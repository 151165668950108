
import { Component, Prop, Vue } from "vue-property-decorator";
import Contributor from "@/models/Contributor";
import Regex from "@/utils/regex";
import * as rhovanion from "@/api/rhovanion";
import Notify from "@/utils/notifications";

@Component
export default class ContributorDrawer extends Vue {
  @Prop() contributor!: Contributor;
  @Prop() show!: boolean;
  @Prop() create!: boolean;

  loading = false;
  rules = {
    name: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
      {
        min: 3,
        message: "Debe contener al menos 3 caracteres.",
      },
    ],
    lastname: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
      {
        min: 3,
        message: "Debe contener al menos 3 caracteres.",
      },
    ],
    email: [
      {
        required: true,
        message: "El campo es obligatorio",
        trigger: "blur",
      },
      {
        type: "email",
        message:
          "Porfavor introduzca una direccion de correo electronica correcta",
        trigger: ["blur", "change"],
      },
    ],
  };

  createContributor() {
    this.loading = true;
    (this.$refs["form"] as HTMLFormElement).validate((valid: boolean) => {
      if (!valid) {
        this.loading = false;
        return false;
      } else {
        rhovanion
          .createContributor(this.contributor)
          .then(() => {
            this.$emit("newDevice" /*, true*/);
            Notify.successful("Colaborador creado exitosamente.");
          })
          .catch(error => {
            Notify.gebServerError(error);
          })
          .finally(() => {
            this.closeDrawer();
          });
      }
    });
  }

  updateContributor() {
    this.loading = true;
    (this.$refs["form"] as HTMLFormElement).validate((valid: boolean) => {
      if (!valid) {
        this.loading = false;
        return false;
      } else {
        if (this.contributor && this.contributor.id) {
          rhovanion
            .updateContributor(this.contributor.id, this.contributor)
            .then(() => {
              this.$emit("newDevice");
              Notify.successful("Colaborador actualizado exitosamente.");
            })
            .catch(error => {
              Notify.gebServerError(error);
            })
            .finally(() => {
              this.closeDrawer();
            });
        }
      }
    });
  }

  closeDrawer() {
    this.loading = false;
    (this.$refs["form"] as HTMLFormElement).clearValidate();
    this.$emit("closeDrawer", false);
  }

  get title() {
    return this.create ? "Crear nuevo colaborador" : "Actualizar colaborador";
  }

  get showDrawer() {
    return this.show;
  }
}
